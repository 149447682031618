import React from 'react'
import Home from '../components/Home'
import { BackendService } from '../Service/BackendService'

export default function HomeController() {
    const backendService = new BackendService();
    const getCAByMonthForYear = async (setValues, setError, Years) => {
        const response = await backendService.getCAByMonthForYear(Years);
            if (response.status === 200) {
                setValues(response.data);
                setError('none');
            }else{
                setError(response.error);
            }
    }

    const getCAByMonthForYearCumul = async (setValues, setError, Years) => {
        const response = await backendService.getCAByMonthForYearCumul(Years);
        if (response.status === 200) {
            setValues(response.data);
            setError('none');
        }else{
            setError(response.error);
        }
    }

    const getSummaryForYear = async (setValues, setError, Years) => {
        const response = await backendService.getSummaryForYear(Years);
        if (response.status === 200) {
            setValues(response.data);
            setError('none');
        }else{
            setError(response.error);
        }
    }

    const getSummaryForTrim = async (setValues, setError, trim) => {
        const response = await backendService.getSummaryForTrim(trim);
        if (response.status === 200) {
            setValues(response.data);
            setError('none');
        }else{
            setError(response.error);
        }
    }

  return (
    <Home getCAByMonthForYear={getCAByMonthForYear} getCAByMonthForYearCumul={getCAByMonthForYearCumul} getSummaryForYear={getSummaryForYear} getSummaryForTrim={getSummaryForTrim}/>
  )
}
