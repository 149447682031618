import React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Toast } from 'react-bootstrap';
import { validation } from '../Utils/validation';
import loader from '../images/loader.svg'

export default function Profile({Submit, loadUserData}) {
  const { register, setValue, handleSubmit, formState: { errors } } = useForm({mode:'onTouched'});
  const onSubmit = data => {Submit(data, toast)} ;
  const [values, setValues] = useState({id:0});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState([]);
  
    useEffect(() => {
        loadUserData(setValues, setError);
    }, [loadUserData]);

    const toast = {
        success: (message) => {
            setType('success');
            setMessage(message);
            setShow(true);
        },
        error: (message) => {
            setType('error');
            setMessage(message);
            setShow(true);
        }
    }

    useEffect(() => {
        if(values.id !== 0 ){
            setLoading(false);
            setValue("Nom", values.lastName);
            setValue("Prenom", values.name);
            setValue("Email", values.mail);
            setValue("NumTel", values.phoneNumber);
            setValue("DateNaissance", values.dateOfBirth);
            setValue("Adresse", values.postalAdress);
            setValue("AdresseCmplt", values.adresseCmplt);
            setValue("CodePostal", values.zipCode);
            setValue("Ville", values.city);
            setValue("CA", values.maxAnnualNumber);
            setValue("TxCharges", values.txCharges);
        }

    }, [values,error, setValue]);

  return (
    <>
    {loading === true && <div className='loader'><img alt='loader' src={loader}/></div>}

    <Container className='mb-3'>
    <Toast onClose={() => setShow(false)} show={show} position={"top-center"} delay={5000}>
          <Toast.Header>
            <strong className="me-auto">{type}</strong>
            <small>Now</small>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className='my-3'>
           <Col>
                <input type="text" className="form-control" placeholder="Nom" {...register("Nom", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
            </Col>
            <Col>
                {errors.Nom && <span className='error-text'>{errors.Nom.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
           <Col>
                <input type="text" className="form-control" placeholder="Prénom" {...register("Prenom", {required: validation.requis, minLength: validation.prenom, maxLength: validation.maxPrenom})} />
            </Col>
            <Col>
                {errors.Prenom && <span className='error-text'>{errors.Prenom.message}</span>}
            </Col>
        </Row >
        <Row className='my-3'>
              <Col>
                <input type="text" disabled className="form-control" placeholder="Email" {...register("Email", {required: validation.requis, pattern: validation.email})} />
            </Col>
            <Col>
                {errors.Email && <span className='error-text'>{errors.Email.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type="tel" className="form-control" placeholder="Numéro de téléphone" {...register("NumTel", {required: validation.requis,minLength: validation.telephone, maxLength: validation.telephone})} />
            </Col>
            <Col>
                {errors.NumTel && <span className='error-text'>{errors.NumTel.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type='date' className="form-control" placeholder='' {...register("DateNaissance", {required: validation.requis})} />
            </Col>
            <Col>
                {errors.DateNaissance && <span className='error-text'>{errors.DateNaissance.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type="text" className="form-control" placeholder="Adresse" {...register("Adresse", {required: validation.requis, minLength:validation.adresse, maxLength: validation.maxAdresse})} />
            </Col>
            <Col>
                {errors.Adresse && <span className='error-text'>{errors.Adresse.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type="text" className="form-control" placeholder="Complément d'adresse" {...register("AdresseCmplt", {maxLength: validation.maxAdresse})} />
            </Col>
            <Col>
                {errors.AdresseCmplt && <span className='error-text'>{errors.AdresseCmplt.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type="text" className="form-control" placeholder="Code postal" {...register("CodePostal", {required: validation.requis, maxLength: validation.codePostal, minLength: validation.codePostal})} />
            </Col>
            <Col>
                {errors.CodePostal && <span className='error-text'>{errors.CodePostal.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type="text" className="form-control" placeholder="Ville" {...register("Ville", {required: validation.requis, maxLength: validation.maxVille})} />
            </Col>
            <Col>
                {errors.Ville && <span className='error-text'>{errors.Ville.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type='number' className="form-control" placeholder="chiffres d'affaires" {...register("CA", {required: validation.requis, min: validation.CA})} />
            </Col>
            <Col>
                {errors.CA && <span className='error-text'>{errors.CA.message}</span>}
            </Col>
        </Row>
        <Row className='my-3'>
            <Col>
                <input type='number' className="form-control" placeholder='taux de charges' {...register("TxCharges", {required: validation.requis, min: validation.txDeCharges, max:validation.maxTxDeCharges})} />
            </Col>
            <Col>
                {errors.TxCharges && <span className='error-text'>{errors.TxCharges.message}</span>}
            </Col>
        </Row>
        <input disabled={0 < Object.values(errors).length ? true : false} className='form-control' type="submit"  value="Sauvegarder les changements"/>
      </form>
    </Container>
    </>
  );
}