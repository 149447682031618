import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { validation } from '../Utils/validation';

export default function Login({Submit}) {
    const { register, handleSubmit, formState: { errors } } = useForm({mode:'onTouched'});
    const onSubmit = data => {Submit(data.email, data.password);};

  return (
    <Container>
    <form onSubmit={handleSubmit(onSubmit)}>
    <label className='ta-c' htmlFor='email'>addresse mail</label>
    <Row className='my-3'>
           
        <Col>
            <input type="email" className='form-control' placeholder="email" {...register("email", {required: validation.requis, pattern: validation.email})} />
        </Col>
        <Col>
            {errors.email && <span className='error-text'>{errors.email.message}</span>}
        </Col>
    </Row >
    <label className='ta-c' htmlFor='password'>mot de passe</label>
    <Row className='my-3'>
        <Col>
            <input className='form-control' type='password' {...register("password", { required: {value:true, message:'Ce champ est requis' }, minLength: validation.motDePasse})} />
        </Col>
        <Col>
            {errors.password && <span className='error-text'>{errors.password.message}</span>}
        </Col>
    </Row>
      {/* errors will return when field validation fails  */}
      <Row className='my-3'>
        <input disabled={0 < Object.values(errors).length ? true : false} className='form-control' type="submit" value='Se connecter'/>
      </Row>
    </form>
    </Container>
  )
}
