import React from 'react'
import { BackendService } from '../Service/BackendService';
import FacturesList from '../components/Factures.list';


export default function FacturesControllerList({factures, error, goToAdd, goToEdit, goToDetails, setFactures, reloadFactures, setError}) {
    const backendService = new BackendService();
    let factureToPass = factures;

    const deleteFactureById = async (id) => {
        const response = await backendService.deleteFactureById(id);
        return response;
    }

    const filterByElement = async (filter, element) => {
        if(element === ''){
            reloadFactures(setFactures, setError);
            factureToPass = factures;
            return;
        }
        const response = await backendService.filterFacturesByElement(filter, element);
        if(response.status === 200){
            if(response.data.length === 0){
                error = 'No Factures found';
                factureToPass = [];
                setFactures([])
            }else{
                error = 'none';
                factureToPass = response.data;
                setFactures(factureToPass)
            }
        }else if (response.status === 401) {
            localStorage.clear();
            window.location.reload();
            }else{
            error = response.error;
        }
        return response;
    }

    return (
        <FacturesList filterByElement={filterByElement} factures={factures} errors={error} goToAdd={goToAdd} deleteFactureById={deleteFactureById} goToEdit={goToEdit} goToDetails={goToDetails} />
    )
}
