import { baseUrl } from "./BackendService";
class ProjectsStatusApi {
    constructor() {
        this.baseUrl = baseUrl + '/api/status';
    }

    async getAllStatus() {
        let RES = {};
        await fetch(this.baseUrl, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la récupération des projects")
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }
    
}

export { ProjectsStatusApi };