import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function ModalDelete({show, handleShow, type}) {
  return (
    <Modal show={show} onHide={handleShow}>
        <Modal.Header closeButton>
        <Modal.Title>
            {
                type === 'USER' && 'Désactiver utilisateurs'
            }
            {
                 'PROJECT' === type && 'Supprimer projet' 
            }
            {
                 'CLIENTS' === type && 'Supprimer Clients'
            }
            {
                'FACTURE' === type && 'Supprimer Facture'
            }
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>Etes vous sûr de vouloir effectué cette action ?</Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={handleShow}>
                Non 
            </Button>
            <Button variant="danger" onClick={handleShow}>
                Oui 
            </Button>
        </Modal.Footer>
    </Modal>
  )
}
