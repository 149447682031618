import React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { validation } from '../Utils/validation';

export default function AdminModalProfile({Submit, User, isDisplayed, closeModal}) {
  const { register, setValue, handleSubmit, formState: { errors } } = useForm({mode:'onTouched'});
  const onSubmit = data => {Submit(data)} ;

    useEffect(() => {
        if(User.id !== 0 ){
            setValue("Nom", User.lastName);
            setValue("Prenom", User.name);
            setValue("Email", User.mail);
            setValue("NumTel", User.phoneNumber);
            setValue("DateNaissance", User.dateOfBirth);
            setValue("Adresse", User.postalAdress);
            setValue("AdresseCmplt", User.adresseCmplt);
            setValue("CodePostal", User.zipCode);
            setValue("Ville", User.city);
            setValue("CA", User.maxAnnualNumber);
            setValue("TxCharges", User.txCharges);
        }

    }, [User, setValue]);

  return (
    <>
    <Modal show={isDisplayed} onHide={closeModal}>
      <Modal.Header closeButton>
      <Modal.Title>
          modification du profil de l'utilisateur {User.name} {User.lastName}
      </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className='mb-3'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className='my-3'>
              <Col>
                    <input type="text" className="form-control" placeholder="Nom" {...register("Nom", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                </Col>
                <Col>
                    {errors.Nom && <span className='error-text'>{errors.Nom.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
              <Col>
                    <input type="text" className="form-control" placeholder="Prénom" {...register("Prenom", {required: validation.requis, minLength: validation.prenom, maxLength: validation.maxPrenom})} />
                </Col>
                <Col>
                    {errors.Prenom && <span className='error-text'>{errors.Prenom.message}</span>}
                </Col>
            </Row >
            <Row className='my-3'>
                  <Col>
                    <input type="text" disabled className="form-control" placeholder="Email" {...register("Email", {required: validation.requis, pattern: validation.email})} />
                </Col>
                <Col>
                    {errors.Email && <span className='error-text'>{errors.Email.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type="tel" className="form-control" placeholder="Numéro de téléphone" {...register("NumTel", {required: validation.requis,minLength: validation.telephone, maxLength: validation.telephone})} />
                </Col>
                <Col>
                    {errors.NumTel && <span className='error-text'>{errors.NumTel.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type='date' className="form-control" placeholder='' {...register("DateNaissance", {required: validation.requis})} />
                </Col>
                <Col>
                    {errors.DateNaissance && <span className='error-text'>{errors.DateNaissance.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type="text" className="form-control" placeholder="Adresse" {...register("Adresse", {required: validation.requis, minLength:validation.adresse, maxLength: validation.maxAdresse})} />
                </Col>
                <Col>
                    {errors.Adresse && <span className='error-text'>{errors.Adresse.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type="text" className="form-control" placeholder="Complément d'adresse" {...register("AdresseCmplt", {maxLength: validation.maxAdresse})} />
                </Col>
                <Col>
                    {errors.AdresseCmplt && <span className='error-text'>{errors.AdresseCmplt.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type="text" className="form-control" placeholder="Code postal" {...register("CodePostal", {required: validation.requis, maxLength: validation.codePostal, minLength: validation.codePostal})} />
                </Col>
                <Col>
                    {errors.CodePostal && <span className='error-text'>{errors.CodePostal.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type="text" className="form-control" placeholder="Ville" {...register("Ville", {required: validation.requis, maxLength: validation.maxVille})} />
                </Col>
                <Col>
                    {errors.Ville && <span className='error-text'>{errors.Ville.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type='number' className="form-control" placeholder="chiffres d'affaires" {...register("CA", {required: validation.requis, min: validation.CA})} />
                </Col>
                <Col>
                    {errors.CA && <span className='error-text'>{errors.CA.message}</span>}
                </Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <input type='number' className="form-control" placeholder='taux de charges' {...register("TxCharges", {required: validation.requis, min: validation.txDeCharges, max:validation.maxTxDeCharges})} />
                </Col>
                <Col>
                    {errors.TxCharges && <span className='error-text'>{errors.TxCharges.message}</span>}
                </Col>
            </Row>
            <input disabled={0 < Object.values(errors).length ? true : false} className='form-control' type="submit"  value="Sauvegarder les changements"/>
            <Button variant="warning" onClick={closeModal}>
                Revenir aux utilisateurs (annule la modification) 
            </Button>
          </form>
        </Container>
      </Modal.Body>
    </Modal>
    </>
  );
}