import React from 'react'
import ProjectsEdit from '../components/Projects.edit'
import { BackendService } from '../Service/BackendService'

export default function ProjectsControllerEdit({project, goToProjectss, setProjects, resetProjects}) {
    const backendService = new BackendService();

    const Submit = async (id, values) => {
        const response = await backendService.updateProjectById(id, values);
        if (response.status === 200) {
            resetProjects();
            values.id = id;
            values.client = project.client;
            values.status = values.statusName;
            setProjects(values)
        }else if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
    }


  const loadStatus = async (setValues, setError) => {
      const response = await backendService.getAllStatus();
      if (response.status === 200) {
          setValues(response.data);
          setError('none');
      }else if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }else{
          setError(response.error);
      }
  }

  return (
    <ProjectsEdit project={project} backToList={goToProjectss} Submit={Submit} loadStatus={loadStatus} />
  )
}
