import React from 'react'
import { BackendService } from '../Service/BackendService';
import FacturesEdit from '../components/Factures.edit';

export default function FacturesControllerEdit({resetFactures, goToFactures, setFactures, factures, numOfFacture}) {
  const backendService = new BackendService();

  let maxNumOfFactures = numOfFacture ? numOfFacture.numFacture+1 : 0;

  const Submit = async (id, values) => {
      values.numFacture = new Date().getFullYear() + "-" + values.numFacture;
      const response = await backendService.updateFactureById(id, values);
      if (response.status === 200) {
          resetFactures();
          values.id = id;
          setFactures(values)
      }else if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
  }

  const loadProjets = async (setValues, setError) => {
    const response = await backendService.getAllProjects();
    if (response.status === 200) {
        setValues(response.data);
        setError('none');
    }else if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }else{
        setError(response.error);
    }
}

  const loadStatusFactures = async (setValues, setError) => {
    const response = await backendService.getAllFacturesStatus();
    if (response.status === 200) {
        setValues(response.data);
        setError('none');
    }else if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }else{
        setError(response.error);
    }
  }
  
  const loadPaiementType = async (setValues, setError) => {
    const response = await backendService.getAllPaiementType();
    if (response.status === 200) {
        setValues(response.data);
        setError('none');
    }else if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }else{
        setError(response.error);
    }
  }

return (
    <FacturesEdit Submit={Submit} maxNumOfFactures={maxNumOfFactures} backToList={goToFactures} loadPaiementType={loadPaiementType} loadProjets={loadProjets} loadStatus={loadStatusFactures}  facture={factures} />
  )
}
