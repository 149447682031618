import React from 'react'
import ProjectsAdd from '../components/Projects.add'
import { BackendService } from '../Service/BackendService'

export default function ProjectsControllerAdd({resetProjects, goToProjects}) {
    const backendService = new BackendService();

    const Submit = async (values) => {
        const response = await backendService.addProject(values);
        if (response.status === 201) {
            resetProjects(); 
        }else if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
    }

    const loadClients = async (setValues, setError) => {
        const response = await backendService.getAllClients();
        if (response.status === 200) {
            setValues(response.data);
            setError('none');
        }else if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }else{
            setError(response.error);
        }
    }

    const loadStatus = async (setValues, setError) => {
        const response = await backendService.getAllStatus();
        if (response.status === 200) {
            setValues(response.data);
            setError('none');
        }else if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }else{
            setError(response.error);
        }
    }

  return (
    <ProjectsAdd backToList={goToProjects} Submit={Submit} loadClients={loadClients} loadStatus={loadStatus} />
  )
}
