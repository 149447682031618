import { ClientsApi } from './ClientsApi.js';
import { ProjectsApi } from './ProjectsApi.js';
import { ProjectsStatusApi } from './ProjectsStatusApi.js';
import { FacturesApi } from './FacturesApi.js';

export const baseUrl = process.env.NODE_ENV === 'production' ? 'https://businez-back.delemarle-maxime.fr' : 'http://localhost:8080';
class BackendService {
    constructor() {
        this.baseUrl = baseUrl;
        this.ClientsApi = new ClientsApi();
        this.ProjectsApi = new ProjectsApi();
        this.ProjectsStatusApi = new ProjectsStatusApi();
        this.FacturesApi = new FacturesApi();
    }

    async getAllUsers() {
        let RES = {};
        await fetch(this.baseUrl + '/api/user', {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error('Mauvais identifiants')
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async login(username, password) {
        let RES = {};
        await fetch(this.baseUrl + '/api/login_check', {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username, password})
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error('Mauvais identifiants')
            }
        })
        .then(response => { RES.token = response.token; RES.user = username })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async register(dataUser) {
        let RES = {};
        await fetch(this.baseUrl + '/api/register', {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({dataUser})
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 201){
                return r.json()
            }else{
                throw new Error(r.json())
            }
        })
        //.then(response => { console.log(response)})
        .then(response => { RES.token = response.token; RES.user = dataUser.Email })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async getUserData() {
        let RES = {};
        await fetch(this.baseUrl + '/api/user/' + sessionStorage.getItem('user'), {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error('Utilisateur non trouvé')
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async updateUser(dataUser) {
        let RES = {};
        await fetch(this.baseUrl + '/api/user/' + sessionStorage.getItem('user'), {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: JSON.stringify({dataUser})
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error('Utilisateur non trouvé')
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async addClient(dataClient) {
        return await this.ClientsApi.addClient(dataClient);
    }

    async getAllClients() {
        return await this.ClientsApi.getAllClients();
    }

    async deleteClientById(id) {
        return await this.ClientsApi.deleteClientById(id);
    }

    async updateClientById(id, dataClient) {
        return await this.ClientsApi.updateClientById(id, dataClient);
    }

    async searchClientByText(filter, text) {
        return await this.ClientsApi.searchClientByText(filter, text);
    }

    async addProject(dataProject) {
        return await this.ProjectsApi.addProject(dataProject);
    }

    async getAllProjects() {
        return await this.ProjectsApi.getAllProjects();
    }

    async deleteProjectById(id) {
        return await this.ProjectsApi.deleteProjectById(id);
    }

    async updateProjectById(id, dataProject) {
        return await this.ProjectsApi.updateProjectById(id, dataProject);
    }

    async getAllStatus() {
        return await this.ProjectsStatusApi.getAllStatus();
    }

    async getAllFactures() {
        return await this.FacturesApi.getAllFactures();
    }

    async getAllFacturesByPage(page) {
        return await this.FacturesApi.getAllFacturesByPages(page);
    }

    async addFacture(dataFacture) {
        return await this.FacturesApi.addFacture(dataFacture);
    }

    async deleteFactureById(id) {
        return await this.FacturesApi.deleteFactureById(id);
    }

    async updateFactureById(id, dataFacture) {
        return await this.FacturesApi.updateFactureById(id, dataFacture);
    }

    async getFactureById(id) {
        return await this.FacturesApi.getFactureById(id);
    }

    async getAllFacturesStatus() {
        return await this.FacturesApi.getAllFacturesStatus();
    }

    async getAllPaiementType() {
        return await this.FacturesApi.getAllPaiementType();
    }
    async filterFacturesByElement(filter, text) {
        return await this.FacturesApi.filterFacturesByElement(filter, text);
    }
    async getCAByMonthForYear(year) {
        return await this.FacturesApi.getCAByMonthForYear(year);
    }

    async getCAByMonthForYearCumul(year) {
        return await this.FacturesApi.getCAByMonthForYearCumul(year);
    }

    async getSummaryForYear(year) {
        return await this.FacturesApi.getSummaryForYear(year);
    }

    async getSummaryForTrim(trim) {
        return await this.FacturesApi.getSummaryForTrim(trim);
    }
}

export { BackendService };