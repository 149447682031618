import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import loader from '../images/loader.svg'

import { validation } from '../Utils/validation';

export default function ProjectsEdit({project, backToList, Submit, loadClients, loadStatus}) {
  const [status, setStatus] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({mode:'onTouched'});

  const onSubmit = data => {
    data.statusName = status.find(status => status.id === parseInt(data.status)).status;
    Submit(project.id, data)
  }

  useEffect(() => {
      loadStatus(setStatus, setErrorStatus).then(() => {
        setLoading(false);
        console.log(errorStatus)
      });
  },[loadClients,loadStatus, errorStatus] )

  useEffect(() => {
    setValue('name', project.name);
    setValue('status', project.statusId);
    setValue('clientName', project.client);
    setValue('clientId', project.clientId);
  }, [project, setValue])

  return (
    <>
      {loading === true && <div className='loader'><img alt='loader' src={loader}/></div>}
            <Container className='mb-3'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row className='my-3'>
                  <Col>
                    <input type="number" hidden className="form-control" placeholder="Id" {...register("clientId", {required: validation.requis})} />
                  </Col>
                </Row>
                <Row className='my-3'>
                  <Col>
                        <input type="text" className="form-control" placeholder="Nom du projet" {...register("name", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                    </Col>
                    <Col>
                        {errors.name && <span className='error-text'>{errors.name.message}</span>}
                    </Col>
                </Row>
                  <Row className='my-3'>
                      <label className='form-label' htmlFor='status'>Status du projet</label>
                  </Row>
                <Row className='my-3'>
                      <Col>
                          <Form.Select  id="status" defaultValue={project.statusId} {...register("status", {required:validation.requis})}>
                              <option disabled selected value="">Choisir un status</option>
                              {
                                  status.map((status, index) => {
                                      return (
                                          <option key={index} disabled={status.id < project.statusId} value={status.id}>{status.status}</option>
                                      )
                                  })
                              }
                          </Form.Select>
                      </Col>
                      <Col>
                        {errors.status && <span className='error-text'>{errors.status.message}</span>}
                      </Col>
                </Row >
                <Row className='my-3'>
                  <label className='form-label' htmlFor='Clients'>Clients du projet</label>
                </Row>
                <Row className='my-3'>
                      <Col>
                          <input type="text" disabled className="form-control" placeholder="Nom du client" {...register("clientName", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                    </Col>
                    <Col>
                        {errors.client && <span className='error-text'>{errors.client.message}</span>}
                      </Col>
                </Row >
                <Row className='my-3'>
                    <Col xs={3}>
                        <input disabled={0 < Object.values(errors).length ? true : false} className='form-control btn btn-success' type="submit"  value="Modifier le projet"/>
                    </Col>
                    <Col xs={3}>
                        <button type="button" className="form-control btn btn-warning" onClick={() => {backToList()}}>Annuler</button>
                    </Col>
                </Row>
            </form>
            </Container>
        </>
  )
}
