import React from 'react'
import { Button, Modal, InputGroup, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import loader from '../images/loader.svg'
import building from '../images/building.png';
import people from '../images/people.png';
import { ClientsFields } from '../Utils/ClientsFields';

export default function Clients({clients, errors, goToAdd, goToEdit, deleteClientById, searchClientByText}) {

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState({id:0});
    const [filter, setFilter] = useState('name');
    const [filterText, setFilterText] = useState('');
    const [processingSearch, setProcessingSearch] = useState(false);
    const [processingDelete, setProcessingDelete] = useState(false);

    const [showDel, setShowDel] = useState(false);

    const handleCloseDel = () => setShowDel(false);
    const handleShowDel = () => setShowDel(true);

    useEffect(() => {
        if(clients.length !== 0 || errors === 'none' ){
            setLoading(false);
        }
    }, [clients,errors, error]);



    let deleteClients = async () => {
        deleteClientById(client.id).then((response) => {
            if(response.status === 200){
                clients.splice(clients.findIndex((element) => element.id === client.id), 1);
            }else{
                setError(response.error);
            }
        }).then(() => {
            handleCloseDel();
        })
        .then(() => setProcessingDelete(false));
    }

  return (
    <>
    {loading === true && <div className='loader'><img alt='loader' src={loader}/></div>}
        <Modal show={showDel} onHide={handleCloseDel} backdrop="static" keyboard={false} >
            <Modal.Header>
                <Modal.Title>Supression du client : {client.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Etes vous sûr de vous sûr de vouloir supprimer le client : {client.name} </Modal.Body>
            <Modal.Footer>
            <Button disabled={processingDelete} variant="secondary" onClick={handleCloseDel}>
                Annulez
            </Button>
            <Button disabled={processingDelete} variant="danger" onClick={() => {setProcessingDelete(true);deleteClients()}}>
                Supprimer {client.name}
            </Button>
            </Modal.Footer>
        </Modal>
        <Col md={9} lg={6}   className='text-center'>
            <InputGroup  className="mb-3">
                <select disabled={clients.length === 0} onChange={evt => setFilter(evt.target.value)} className="form-control">
                    {
                        clients.length !== 0 &&
                            Object.keys(clients[0]).map((key, index) => {
                                if(Object.keys(ClientsFields).includes(key))
                                {
                                    return (
                                        <option key={index} value={key}>{ClientsFields[key]}</option>
                                    )
                                }else{
                                    return null;
                                }
                                
                            })
                    }
                </select>
                <input disabled={clients.length === 0} type="text" onChange={evt => setFilterText(evt.target.value)} className="form-control" placeholder="Rechercher un client" aria-label="Rechercher un client" aria-describedby="basic-addon2"/>
                <Button disabled={clients.length === 0 || processingSearch } onClick={() => {setProcessingSearch(true);searchClientByText(filter,filterText).then(() => setProcessingSearch(false));}} variant="success">
                    Rechercher
                </Button >
            </InputGroup>
        </Col>
        {
            clients.length !== 0 &&
                <div style={{overflowX:"auto"}}>
                    <table className="table table-striped table-borderless">
                        <thead>
                            <tr>
                                <th>Nom du clients</th>
                                <th>Prénom/Nom de contact</th>
                                <th>Type</th>
                                <th>Email</th>
                                <th>Adresse</th>
                                <th>Ville</th>
                                <th>Téléphone</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            clients.map((c, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{c.name}</td>
                                        <td>{c.nameOfContactOrFirstName}</td>
                                        <td>{c.isEntreprise === true ? <img style={{width: '40px'}} src={building} alt='entreprise' /> : <img style={{width: '40px'}} src={people} alt='personne' /> }</td>
                                        <td>{c.mail}</td>
                                        <td>{c.Address}</td>
                                        <td>{c.ZipCode} {c.City}</td>
                                        <td>{c.PhoneNumber}</td>
                                        <td>
                                            <button type="button" className="btn btn-primary" onClick={() => {setClient(c);goToEdit(c.id)}}>Modifier</button>
                                            { c.nbrProject === 0 && <button type="button" className="btn btn-danger" onClick={() => {handleShowDel(); setClient(c)}}>Supprimer</button>}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
        } 
        <Button type="button" className="btn btn-success" onClick={() => {goToAdd()}}>Ajouter un client</Button>
    </>

  )
}
