import React from 'react'
import ClientEdit from '../components/client.edit'
import { BackendService } from '../Service/BackendService'

export default function ClientControllerEdit({resetClient, client, goToClients, setClient}) {
    const backendService = new BackendService();

    const Submit = async (id, values) => {
        const response = await backendService.updateClientById(id, values);
        if (response.status === 201) {
            resetClient();
            values.id = id;
            setClient(values)
        }else if (response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
    }

  return (
    <ClientEdit client={client} backToList={goToClients} Submit={Submit} />
  )
}
