import React from 'react'
import { BackendService } from '../Service/BackendService';
import Login from '../components/Login';
import jwt_decode from "jwt-decode";

export default function LoginController() {
    const backendService = new BackendService();

    const Submit = async (email, password) => {
        const response = await backendService.login(email, password);
        if (response.status === 200) {
            const decoded = jwt_decode(response.token);
            sessionStorage.setItem('roles', decoded.roles);
            sessionStorage.setItem('token', response.token);
            sessionStorage.setItem('user', response.user);
            window.location.href = '/';
        }else{
            alert(response.error);
        }
    }
    
  return (
    <Login Submit={Submit}/>
  )
}
