import React from 'react'
import AdminModalProfile from '../components/AdminModalProfile'
import { BackendService } from '../Service/BackendService';

export default function ProfileController({showModal, toast, user, closeModal}) {
    const backendService = new BackendService();

    const Submit = async (data) => {
        const response = await backendService.updateUser(data)
        if (response.status === 200) {
            toast.success("Les informations ont été modifiées avec succès !");
            closeModal();
        }else{
            toast.error("Une erreur est survenue lors de la modification des informations !");
        }
    }
    

  return (
    <AdminModalProfile Submit={Submit} User={user} isDisplayed={showModal} closeModal={closeModal} />
  )
}
