import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
import loader from '../images/loader.svg'

import { validation } from '../Utils/validation';

export default function FacturesDetails({ backToList, Submit, loadProjets, loadStatus, loadPaiementType, facture}) {
  const [loading, setLoading] = useState(true);

  const { register, setValue, control } = useForm({
        mode:'onTouched'
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "facturationligne"
    });

  useEffect(() => {
    setValue('paiementType', facture.paiementTypeId);
    setValue('projectId', facture.projectId);
    setValue('dateOfEdition', facture.dateOfEdition);
    setValue('dateOfPayement', facture.dateOfPayement);
    setValue('status', facture.statusId);
    setValue('numFacture', facture.years +'-'+ facture.numFacture);
    setValue('dateLimitOfPayement', facture.dateLimitOfPayement);
    facture.lignes.forEach((fl, i) => {
        remove(i);
        append({libelle: fl.libelle, QT: fl.QT, priceUnit: fl.priceUnit})
    });
    setValue('footNote', facture.footNote);
    setLoading(false);
    }, [facture, append, remove, setValue])

  return (
    <>
      {loading === true && <div style={{display:'flex'}}><div className='loader' style={{flex: 1}}><img alt='loader' src={loader}/></div></div>}
        {loading === false && 
            <Container className='mb-3'>
                <form >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='NumFact'>Numéro de facture </label>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={6}>
                            <input type="text" disabled id='NumFact' className="form-control" placeholder="Numéro de facture" {...register("numFacture", {required: validation.requis})} />
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfEdition'>date d'émission</label>
                    </Row>
                    <Row className='my-3'>
                    <Col xs={6}>
                            <input type="date" disabled id="dateOfEdition" className="form-control" {...register("dateOfEdition", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                    </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfLimitPayement'>date limite de paiement</label>
                    </Row>
                    <Row className='my-3'>
                    <Col xs={6}>
                            <input type="date" disabled id="dateLimitOfPayement" className="form-control" {...register("dateLimitOfPayement", {required: validation.requis})} />
                    </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfPayement'>date de paiement</label>
                    </Row>
                    <Row className='my-3'>
                    <Col xs={6}>
                            <input type="date" disabled id="dateOfPayement" className="form-control" {...register("dateOfPayement", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                    </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='status'>Status de la facture</label>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={6}>
                            <input type='text' disabled className='form-control' defaultValue={facture.status}/>
                        </Col>
                    </Row >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='Projets'>Liés aux projets </label>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={6}>
                            <input type='text' disabled className='form-control' defaultValue={facture.project}/>
                        </Col>
                    </Row >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='paiementType'>Type de paiement </label>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={6}>
                            <input defaultValue={facture.paiementType} disabled type="text" id='paiementType' className="form-control" placeholder="Type de paiement" {...register("paiementType", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                        </Col>
                    </Row >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='FacturationLine'>Ligne de facturation </label>
                    </Row>
                    <div id='FacturationLine'>
                        {
                            fields.map((field, index) => {
                                return (
                                    <Row key={field.id} className='my-3'>
                                        <Row className='my-3'>
                                            <Col>
                                                <input type="text" disabled id='FacturationLineT' className="form-control" placeholder="libelle" {...register(`facturationligne[${index}].libelle`, {required: validation.requis, minLength: validation.libelle, maxLength: validation.maxlibelle})} />
                                            </Col>
                                            <Col>
                                                <input type="number" disabled id='FacturationLineQ' className="form-control" placeholder="prix" {...register(`facturationligne[${index}].priceUnit`, {required: validation.requis, min: validation.prix})} />
                                            </Col>
                                            <Col>
                                                <input type="number" disabled id='FacturationLineS' className="form-control" placeholder="quantite" {...register(`facturationligne[${index}].QT`, {required: validation.requis, min: validation.minQuantite})} />
                                            </Col>
                                            <Col lg={2}>
                                            </Col>
                                        </Row>
                                    </Row >
                                )
                            })
                        }
                    </div>
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='FootNote'>Note de bas de page </label>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={6}>
                            <input type="text" disabled defaultValue={facture.footNote} id='FootNote' className="form-control" placeholder="Note de bas de page" {...register("footNote", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={3}>
                            <button type="button" className="form-control btn btn-warning" onClick={() => {backToList()}}>Retour à la liste</button>
                        </Col>
                    </Row>
                </form>
            </Container>
    }
        </>
  )
}