import { baseUrl } from "./BackendService";
class ProjectsApi {
    constructor() {
        this.baseUrl = baseUrl + '/api/project';
    }

    async getAllProjects() {
        let RES = {};
        await fetch(this.baseUrl, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la récupération des projects")
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async addProject(data) {
        let RES = {};
        let d = JSON.stringify(data)
        await fetch(this.baseUrl, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: d
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 201){
                return r.json()
            }else if (r.status === 200){
                throw new Error("Project déjà existant")
            }else{
                throw new Error("Erreur lors de la création du project")
            }
        })
        .then(response => { RES.message = "projects créer avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async deleteProjectById(id) {
        let RES = {};
        await fetch(this.baseUrl + '/' + id, {
            method: 'Delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la suppression du project")
            }
        })
        .then(response => { RES.message = "Project supprimé avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }
    
    async updateProjectById (id, data) {
        let RES = {};
        let d = JSON.stringify(data)
        await fetch(this.baseUrl + '/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: d
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la modification du project")
            }
        })
        .then(response => { RES.message = "Project modifié avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async searchProjectByText(filter, text) {
        let RES = {};
        await fetch(this.baseUrl + '/search/' + filter + '/' + text, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la recherche du project")
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }
    
}

export { ProjectsApi };