import React from 'react'
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap'

export default function NavbarBusinez({ logOut, page, isConnected}) {
  let roles = sessionStorage.getItem('roles');
  return (

    <div className='vw-100 bg-black mx-0 px-0 mb-2 testStick'>
        <Navbar collapseOnSelect={true} key={false} bg="dark"  expand={false} variant="dark" className="mb-3 d-block d-lg-none">
          <Container fluid>
            <Navbar.Brand href="#">Businez</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-false`}
              aria-labelledby={`offcanvasNavbarLabel-expand-false`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                  Businez
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href='#' onClick={() => page.changementDePage('Accueil')} >Accueil</Nav.Link>
                  {
                    isConnected ?
                    <>
                      <Nav.Link href='#' onClick={() => page.changementDePage('Clients')} >Clients</Nav.Link>
                      <Nav.Link href='#' onClick={() => page.changementDePage('Projets')} >Projets</Nav.Link>
                      <Nav.Link href='#' onClick={() => page.changementDePage('Factures')} >Factures</Nav.Link>
                      {
                        roles === 'ROLE_ADMIN' && <Nav.Link href='#' onClick={() => page.changementDePage('admin')} >Admin</Nav.Link>
                      }
                      <hr></hr>
                      <Nav.Link href='#' onClick={() => page.changementDePage('Profile')} >Profile</Nav.Link>
                      <Nav.Link href='#' onClick={() => logOut() } >Se déconnecter</Nav.Link>
                    </>
                    :
                    <>
                      <Nav.Link href='#' onClick={() => page.changementDePage('register') } >S'inscrire</Nav.Link>
                      <Nav.Link href='#' onClick={() => page.changementDePage('login')} >Se connecter</Nav.Link>
                    </>
                  }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      
      <Navbar collapseOnSelect={true} className="d-none d-lg-block" bg="dark" variant="dark">
          <Container fluid>
            <Navbar.Brand>Businez</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link onClick={() => page.changementDePage('Accueil')} >Accueil</Nav.Link>
                {
                  isConnected &&
                  <>
                    <Nav.Link onClick={() => page.changementDePage('Clients')} >Clients</Nav.Link>
                    <Nav.Link onClick={() => page.changementDePage('Projets')} >Projets</Nav.Link>
                    <Nav.Link onClick={() => page.changementDePage('Factures')} >Factures</Nav.Link>
                    {
                      roles === 'ROLE_ADMIN' && <Nav.Link onClick={() => page.changementDePage('admin')} >Admin</Nav.Link>
                    }
                  </>
                }
              </Nav>
              <Nav className="justify-content-end">
                {
                  isConnected ?
                  <>
                    <Nav.Link onClick={() => logOut() } >Se déconnecter</Nav.Link>
                    <Nav.Link onClick={() => page.changementDePage('Profile')} >Profile</Nav.Link>
                  </>
                  :
                  <>
                    <Nav.Link onClick={() => page.changementDePage('register') } >S'inscrire</Nav.Link>
                    <Nav.Link onClick={() => page.changementDePage('login')} >Se connecter</Nav.Link>
                  </>
                }

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    </div>
  )
}
