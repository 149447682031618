import { baseUrl } from "./BackendService";
class FacturesApi {
    constructor() {
        this.baseUrl = baseUrl + '/api/facture';
    }

    async getAllFactures() {
        let RES = {};
        await fetch(this.baseUrl, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }

    async getAllFacturesByPages(page) {
        let RES = {};
        await fetch(this.baseUrl+'/page/'+page, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }

    async addFacture(data) {
        let RES = {};
        let d = JSON.stringify(data)
        await fetch(this.baseUrl, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: d
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 201){
                return json
            }else if (r.status === 200){
                throw new Error("Facture déjà existant")
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.message = "Factures créer avec succès" })
        .catch(error => { 
            RES.data = error.message
         })
        return RES;
    }

    async deleteFactureById(id) {
        let RES = {};
        await fetch(this.baseUrl + '/' + id, {
            method: 'Delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.message = "Facture supprimé avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }
    
    async updateFactureById (id, data) {
        let RES = {};
        let d = JSON.stringify(data)
        await fetch(this.baseUrl + '/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: d
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.message = "Facture modifié avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async searchFactureByText(filter, text) {
        let RES = {};
        await fetch(this.baseUrl + '/search/' + filter + '/' + text, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }

    async getAllFacturesStatus() {
        let RES = {};
        await fetch(this.baseUrl + '/status', {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }

    async getAllPaiementType() {
        let RES = {};
        await fetch(this.baseUrl + '/paiementType', {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }
    
    async filterFacturesByElement(filter, element) {
        let RES = {};
        await fetch(this.baseUrl + '/filter/' + filter + '/' + element, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }

    async getCAByMonthForYear(year) {
        let RES = {};
        await fetch(this.baseUrl + '/years/' + year , {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }
    
    async getCAByMonthForYearCumul(year) {
        let RES = {};
        await fetch(this.baseUrl + '/years/' + year + '/cumul' , {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }
    
    async getSummaryForYear(year) {
        let RES = {};
        await fetch(this.baseUrl + '/summary/' + year , {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }

    
    async getSummaryForTrim() {
        let RES = {};
        await fetch(this.baseUrl + '/summary/trim', {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            let json = r.json()
            RES.status =  r.status
            if(r.status === 200){
                return json
            }else{
                return json.then(Promise.reject.bind(Promise));
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error })
        return RES;
    }

}

export { FacturesApi };