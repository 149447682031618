import React from 'react'
import Register from '../components/Register';
import { BackendService } from '../Service/BackendService';
import jwt_decode from "jwt-decode";

export default function RegisterController() {
  const backendService = new BackendService();

  const Submit = async (data) => {
      const response = await backendService.register(data);
      if (response.status === 201) {
          const decoded = jwt_decode(response.token);
          sessionStorage.setItem('roles', decoded.roles);
          sessionStorage.setItem('token', response.token);
          sessionStorage.setItem('user', response.user);
          window.location.href = '/';
      }else{
          alert(response.message);
      }
  }
   
  return (
    <Register Submit={Submit} />
  )
}
