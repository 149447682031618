import React from 'react'
import Profile from '../components/Profile'
import { BackendService } from '../Service/BackendService';

export default function ProfileController() {
    const backendService = new BackendService();

    const loadUserData = async (setValues, setError) => {
        const response = await backendService.getUserData();
        if (response.status === 200) {
            setValues(response.data);
        }else if (response.status === 401) {
            localStorage.clear();
            window.location.reload();
        }else{
            setError(response.error);
        }
    }

    const Submit = async (data, Toast) => {
        const response = await backendService.updateUser(data)
        if (response.status === 200) {
            Toast.success("Vos informations ont été modifiées avec succès !");
        }else{
            Toast.error("Une erreur est survenue lors de la modification de vos informations !");
        }
    }
    

  return (
    <Profile Submit={Submit} loadUserData={loadUserData}/>
  )
}
