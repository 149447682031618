import React from 'react'
import { Button, Modal, InputGroup, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import loader from '../images/loader.svg'
import { FacturesFields } from '../Utils/FacturesFields';

export default function FacturesList({factures, errors, goToAdd, goToEdit, deleteFactureById, filterByElement, goToDetails}) {
 
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState({id:0});
  const [filter, setFilter] = useState('client');
  const [filterText, setFilterText] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [processingSearch, setProcessingSearch] = useState(false);
  const [processingDelete, setProcessingDelete] = useState(false);

  const [showDel, setShowDel] = useState(false);

  const handleCloseDel = () => setShowDel(false);
  const handleShowDel = () => setShowDel(true);

  useEffect(() => {
      if((factures.length !== 0 || errors === 'none') && loading === true ){
          setLoading(false);
          if(loading === true){
          getUniqueClient(factures);
          getUniqueProject(factures);
        }
      }
  }, [factures,errors, error, loading]);    

  const getUniqueProject = (arr) => {
    const arrProject = [];
    arr.forEach(obj => {
      if (obj.hasOwnProperty('project')) {
        let objProject = {id: obj.projectId, project: obj.project}
        arrProject.push(objProject);
      }
    });
    function compareObjectsJSON(objet1, objet2) {
        return JSON.stringify(objet1) === JSON.stringify(objet2);
      }
      
    // Tableau résultat sans objets en double
    const result = arrProject.filter((objet, index) => {
    return (
        index ===
        arrProject.findIndex((item) => compareObjectsJSON(item, objet))
    );
    });
    
    setProjectList(result);
  }

  const getUniqueClient = (arr) => {
    const arrProject = [];
    arr.forEach(obj => {
      if (obj.hasOwnProperty('client')) {
        let objClient = {id: obj.clientId, client: obj.client}
        arrProject.push(objClient);
      }
    });
    function compareObjectsJSON(objet1, objet2) {
        return JSON.stringify(objet1) === JSON.stringify(objet2);
      }
      
    // Tableau résultat sans objets en double
    const result = arrProject.filter((objet, index) => {
    return (
        index ===
        arrProject.findIndex((item) => compareObjectsJSON(item, objet))
    );
    });
    setClientList(result);
  }

  let deletefactures = async () => {
      deleteFactureById(project.id).then((response) => {
          if(response.status === 200){
              factures.splice(factures.findIndex((element) => element.id === project.id), 1);
          }else{
              setError(response.error);
          }
      }).then(() => {
          handleCloseDel();
      })
      .then(() => setProcessingDelete(false));
  }

return (
  <>
  {loading === true && <div className='loader'><img alt='loader' src={loader}/></div>}
      <Modal show={showDel} onHide={handleCloseDel} backdrop="static" keyboard={false} >
          <Modal.Header>
              <Modal.Title>Supression de la facture : {project.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Etes vous sûr de vous sûr de vouloir supprimer la facture : {project.name} </Modal.Body>
          <Modal.Footer>
          <Button disabled={processingDelete} variant="secondary" onClick={handleCloseDel}>
              Annulez
          </Button>
          <Button disabled={processingDelete} variant="danger" onClick={() => {setProcessingDelete(true);deletefactures()}}>
              Supprimer {project.name}
          </Button>
          </Modal.Footer>
      </Modal>
      <Col xs={6} className='text-center'>
          <InputGroup  className="mb-3">
              <select disabled={factures.length === 0} onChange={evt => {setFilter(evt.target.value); setFilterText("")}} className="form-control">
                  {
                      factures.length !== 0 &&
                          Object.keys(factures[0]).map((key, index) => {
                              if(Object.keys(FacturesFields).includes(key))
                              {
                                  return (
                                      <option key={index} value={key}>{FacturesFields[key]}</option>
                                  )
                              }else{
                                  return null;
                              }
                              
                          })
                  }
              </select>
              <select value={filterText} disabled={factures.length === 0} onChange={evt => setFilterText(evt.target.value)} className="form-control">
                <option value="">Tous</option>
                    {
                        filter === 'client' ?
                            clientList.map((c, index) => {
                                return (
                                    <option key={index} value={c.id}>{c.client}</option>
                                )
                            })
                        :
                        projectList.map((p, index) => {
                            return (
                                <option key={index} value={p.id}>{p.project}</option>
                            )
                        })
                    }
              </select>
              <Button disabled={factures.length === 0 || processingSearch } onClick={() => {setProcessingSearch(true);filterByElement(filter,filterText).then(() => setProcessingSearch(false));}} variant="success">
                  Rechercher
              </Button >
          </InputGroup>
      </Col>
      {
          factures.length !== 0 &&
              <div style={{overflowX:"auto"}}>
                  <table className="table table-striped table-borderless">
                      <thead>
                          <tr>
                              <th>Numéro de facture</th>
                              <th>Projet</th>
                              <th>Client</th>
                              <th>Status</th>
                              <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                      {
                          factures.map((p, index) => {
                              return (
                                
                                    <tr className={p.dateOfPayement === '' && p.statusId === 2 && p.dateLimitOfPayement < new Date().toISOString().split('T')[0] && 'bgc-red'} key={index}>
                                      <td>{p.years}-{p.numFacture}</td>
                                      <td>{p.project}</td>
                                      <td>{p.client}</td>
                                      <td>{p.status}</td>
                                      <td>
                                          { p.statusId !== 3  ? <button type="button" className="btn btn-primary" onClick={() => {setProject(p);goToEdit(p.id)}}> Modifier </button> : <button type="button" className="btn btn-primary" onClick={() => {goToDetails(p.id)}}>Details</button> }
                                          { p.statusId !== 3 && p.statusId !== 2 &&<button type="button" className="btn btn-danger" onClick={() => {handleShowDel(); setProject(p)}}> Supprimer </button>}
                                      </td>
                                  </tr>
                              )
                          })
                      }
                      </tbody>
                  </table>
              </div>
      } 
      <Button type="button" className="btn btn-success" onClick={() => {goToAdd()}}>Ajouter une Facture</Button>
  </>
  )
}