import React from 'react'
import { useState, useEffect } from 'react'
import FacturesControllerList from './FacturesController.list'
import FacturesControllerAdd from './FacturesController.add'
import FacturesControllerEdit from './FacturesController.edit'
import FacturesControllerDetails from './FacturesController.details'
import { BackendService } from '../Service/BackendService';

export default function FacturesController() {
    const [values, setValues] = useState([]);
    const [factures, setFactures] = useState({});
    const [error, setError] = useState(null);

    const [page, setPage] = useState('list');

    const reloadFactures = async (setValues, setError) => {
        const backendService = new BackendService();
        const response = await backendService.getAllFactures();
        if (response.status === 200) {
            setValues(response.data);
            setError('none');
        }else if (response.status === 401) {
            localStorage.clear();
            window.location.reload();
            }else{
            setError(response.error);
        }
    }

    useEffect(() => {
        const backendService = new BackendService();
        const loadFactures = async (setValues, setError) => {
            const response = await backendService.getAllFactures();
            if (response.status === 200) {
                setValues(response.data);
                setError('none');
            }else if (response.status === 401) {
                localStorage.clear();
                window.location.reload();
                }else{
                setError(response.error);
            }
        }
        if(page === 'list')
        {
            loadFactures(setValues, setError)
        }
    }, [page] )

    function goToAdd() {
        setPage('add');
    }

    function goToEdit(id) {
        values.filter((factures) => {
            if (factures.id === id) {
                setFactures(factures);
                return factures;
            }
            return null;
        });
        setPage('edit');
    }

    function goToFactures() {
        setPage('list')
    }

    function goToDetails(id) {
        values.filter((factures) => {
            if (factures.id === id) {
                setFactures(factures);
                return factures;
            }
            return null;
        });
        setPage('details');
    }

    function resetFactures() {
        setPage('list')
        setError('loading')
        setValues([])
    }

  return (
    <>
    {
        page === 'list' &&
        <FacturesControllerList goToDetails={goToDetails} reloadFactures={reloadFactures} factures={values} setFactures={setValues} error={error} setError={setError} goToAdd={goToAdd} goToEdit={goToEdit} />
    }
    {
        page === 'add' &&
        <FacturesControllerAdd numOfFacture={values[0]} resetFactures={resetFactures} goToFactures={goToFactures}/>
    }
    {
        page === 'edit' &&
        <FacturesControllerEdit numOfFacture={values[0]} factures={factures} goToFactures={goToFactures} resetFactures={resetFactures} setFactures={setFactures}/>
    }
    {
        page === 'details' &&
        <FacturesControllerDetails factures={factures} goToFactures={goToFactures}/>
    }
    </>
  )
}
