import React from 'react'
import ClientsAdd from '../components/Clients.add'
import { BackendService } from '../Service/BackendService';

export default function ClientsControllerAdd({resetClient, goToClients}) {
    const backendService = new BackendService();

    const Submit = async (data) => {
      const response = await backendService.addClient(data);
      if (response.status === 201) {
         resetClient();
      }else if(response.status === 200){
          alert(response.error);
      }else if (response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }else{
          alert(response.error);
      }
    }


  return (
    <ClientsAdd backToList={goToClients} Submit={Submit}/>
  )
}
