export const validation = {
    requis: {
        value: true,
        message: 'Ce champ est requis'
    },
    email: {
        value: /^\S+@\S+$/i,
        message: 'Veuillez entrer une adresse mail valide'
    },
    motDePasse: {
        value: 8,
        message: 'Le mot de passe doit contenir au moins 8 caractères'
    },
    tailleTelephone: {
        value: 10,
        message: 'Le numéro de téléphone doit contenir 10 chiffres'
    },
    telephone: {
        value: /0\d{9}/g,
        message: 'Le numéro de téléphone doit contenir 10 CHIFFRES et commencer par 0'
    },

    nom: {
        value: 2,
        message: 'Le nom doit contenir au moins 2 caractères'
    },
    maxNom: {
        value: 100,
        message: 'Le nom doit contenir moins de 100 caractères'
    },
    prenom: {
        value: 2,
        message: 'Le prénom doit contenir au moins 2 caractères'
    },
    maxPrenom: {
        value: 80,
        message: 'Le prénom doit contenir moins de 80 caractères'
    },
    adresse: {
        value: 2,
        message: 'L\'adresse doit contenir au moins 2 caractères'
    },
    maxAdresse: {
        value: 100,
        message: 'L\'adresse doit contenir  moins de 100 caractères'
    },
    codePostal: {
        value: 5,
        message: 'Le code postal doit contenir 5 chiffres'
    },
    ville: {
        value: 2,
        message: 'La ville doit contenir au moins 2 caractères'
    },
    CA: {
        value: 1,
        message: 'Le chiffre d\'affaire doit être supérieur à 1'
    },
    txDeCharges: {
        value: 0,
        message: 'Le taux de charges doit être supérieur à 0'
    },
    maxTxDeCharges: {
        value: 100,
        message: 'Le taux de charges doit être inférieur à 100'
    },
    dateDeNaissance: {
        value: 18,
        message: 'Vous devez avoir au moins 18 ans'
    },
    minQuantite: {
        value: 0,
        message: 'La quantité doit être supérieure à 0'
    },
    prix: {
        value: 0,
        message: 'Le prix doit être supérieur à 0'
    },
    libelle: {
        value: 2,
        message: 'Le libelle doit contenir au moins 2 caractères'
    },
    maxlibelle: {
        value: 100,
        message: 'Le libelle doit contenir moins de 100 caractères'
    },
    dateLimite : {
        message: "La date limite de paiement doit être supérieure à la date d'édition de la facture"
    }
}

