import React, { useEffect } from 'react'
import { useState } from 'react'
import ClientsControllerList from './ClientsController.list'
import ClientsControllerAdd from './ClientsController.add'
import { BackendService } from '../Service/BackendService'
import ClientControllerEdit from './ClientController.edit'

export default function ClientsController() {
    const [values, setValues] = useState([]);
    const [client, setClient] = useState({});
    const [error, setError] = useState(null);

    const [page, setPage] = useState('list');

    function goToAdd() {
        setPage('add');
    }

    function goToEdit(id) {
        values.filter((client) => {
            if (client.id === id) {
                setClient(client);
                return client;
            }
            return null;
        });
        setPage('edit');
    }

    function goToClients() {
        setPage('list')
    }

    function resetClient() {
        setPage('list')
        setError('loading')
    }

    useEffect(() => {
        const backendService = new BackendService();
        const loadClients = async (setValues, setError) => {
            const response = await backendService.getAllClients();
            if (response.status === 200) {
                setValues(response.data);
                setError('none');
            }else if (response.status === 401) {
                localStorage.clear();
                window.location.reload();
              }else{
                setError(response.error);
            }
        }

        if(page === 'list')
        {
            loadClients(setValues, setError)
        }
    }, [page] )

    useEffect(() => {
        if(page === 'list' && client.id !== undefined){
            let newValues = []
            values.forEach((c) => {
                if (c.id === client.id) {
                    newValues.push(client); 
                    setClient({});
                }else {
                    newValues.push(c);
                }
            });
            setValues(newValues);
        }
    }, [page,client,values])

  return (
    <>
    {
        page === 'list' &&
        <ClientsControllerList clients={values} setClients={setValues} error={error} setError={setError} goToAdd={goToAdd} goToEdit={goToEdit} />
    }
    {
        page === 'add' &&
        <ClientsControllerAdd resetClient={resetClient} goToClients={goToClients}/>
    }
    {
        page === 'edit' &&
        <ClientControllerEdit client={client} setClient={setClient} goToClients={goToClients} resetClient={resetClient}/>
    }
    </>
  )
}
