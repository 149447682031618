import React, { useEffect } from 'react'
import { Card, Button, Row, Col, Toast } from 'react-bootstrap';
import {  useState } from 'react';
import loader from '../images/loader.svg';
import ModalDeleteController from '../Controller/ModalDeleteController';
import AdminModalProfileController from '../Controller/AdminModalProfileController';


export default function Admin({Submit, loadUserData}) {
  const [values, setValues] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState([]);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [user, setUser] = useState({id:0})

    useEffect(() => {
        loadUserData(setValues, setError);
    }, [loadUserData]);

    const toast = {
        success: (message) => {
            setType('success');
            setMessage(message);
            setShow(true);
        },
        error: (message) => {
            setType('error');
            setMessage(message);
            setShow(true);
        }
    }

    useEffect(() => {
        if(values.length !== 0) {
            setLoading(false);
        }
    }, [values, error]);

    const closeModalDelete = () => {
        setShowModalDelete(false);
        setUser({id:0});
    }

    const closeModalProfile = () => {
        setShowModalProfile(false);
        setUser({id:0});
    }

    const openModalProfile = (user) => {
        setUser(user);
        setShowModalProfile(true);
    }

    const openModalDelete = (user) => {
        setUser(user);
        setShowModalDelete(true);
    }

    return (
        <>
            {loading === true && <div className='loader'><img alt='loader' src={loader}/></div>}

            <Toast onClose={() => setShow(false)} show={show} position={"top-center"} autoClose={5000} hideProgressBar={false} closeOnClick={true} pauseOnHover={true}>
                <Toast.Header>
                    <strong className="me-auto">{type}</strong>
                    <small>Now</small>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
            <AdminModalProfileController showModal={showModalProfile} toast={toast} user={user} closeModal={closeModalProfile}/>
            <ModalDeleteController showModal={showModalDelete} toast={toast} user={user} type={'USER'} closeModal={closeModalDelete} />
            <Row>
                {
                    values.map((value) => {
                        return(
                            <Col key={value.mail}>
                                <Card >
                                    <Card.Body className='ta-c'>
                                        <Card.Title>{value.mail}</Card.Title>
                                        <Card.Text>
                                            {value.name} {value.lastName}
                                        </Card.Text>
                                        <Button variant="info" onClick={() => openModalProfile(value)} >Voir/modifier les informations</Button>
                                        <Button variant="danger" onClick={() => openModalDelete(value)}>Désactiver l'Utilisateur</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    )
  }
