import React, { useEffect, useState } from 'react'
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
import loader from '../images/loader.svg'

import { validation } from '../Utils/validation';

export default function FacturesEdit({ backToList, Submit, loadProjets, loadStatus, loadPaiementType, facture, maxNumOfFactures}) {
  const [projets, setProjets] = useState([]);
  const [errorProjets, setErrorProjets] = useState(null);
  const [status, setStatus] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paiementType, setPaiementType] = useState([]);
  const [errorPaiementType, setErrorPaiementType] = useState(null);
  const [errorsPostSubmit, setErrorsPostSubmit] = useState({});
  const [statusFiltered, setStatusFiltered] = useState([])

  const { register, getValues, handleSubmit, setValue, unregister, watch, formState: { errors }, control } = useForm({
        mode:'onTouched'
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "facturationligne"
    });

  const onSubmit = data => {
    if(data.statusId === '0' || data.statusId === 0 || data.facturationligne.length !== 0) {
        setErrorsPostSubmit({})
        Submit(facture.id, data)
    }else {
        setErrorsPostSubmit({ message: 'Vous devez ajouter au moins une ligne de facturation'})
    }
  }
  
  useEffect(() => {
    let t = status.filter(
        state => {
            return state.status === facture.status
        }
    )
    setStatusFiltered(status.slice(status.indexOf(t[0]), status.indexOf(t[0])+2))
  },[setStatusFiltered, facture, status])

  useEffect(() => {
    Promise.all([
        loadStatus(setStatus, setErrorStatus),
        loadProjets(setProjets, setErrorProjets),
        loadPaiementType(setPaiementType, setErrorPaiementType)
    ]).then(() => {
        setLoading(false);
        let e = errorProjets === 'none' && errorStatus === 'none' && errorPaiementType === 'none' ? false : true;
        let t = (e) => null
        t(e);
    });
  },[loadProjets,loadStatus, loadPaiementType, errorPaiementType, errorProjets, errorStatus] )


  const statu = watch("statusId")

    useEffect(() => {
        if(statu === 0 || statu === "0"){
            setValue('dateOfEdition', facture.dateOfEdition)
            setValue('dateLimitOfPayement', facture.dateLimitOfPayement)
            setValue('dateOfPayement',facture.dateOfPayement)
            unregister(["dateOfEdition","dateLimitOfPayement","dateOfPayement","paiementType","footNote"]);
        } else {
            unregister(["dateOfEdition","dateLimitOfPayement","dateOfPayement","paiementType","footNote"]);
            register("dateOfEdition", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom, value: facture.dateOfEdition });
            register("dateLimitOfPayement", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom, value: facture.dateLimitOfPayement, validate: value => value > getValues('dateOfEdition') || validation.dateLimite});
            statu === 2 ? register("dateOfPayement", {required: validation.requis}) : register("dateOfPayement");
            register("paiementType", {required: validation.requis, value: facture.paiementTypeId});
            register("footNote", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom, value: facture.footNote});
        }
    }, [statu, register, unregister, facture, setValue, getValues])

  useEffect(() => {
    setValue('projectId', facture.projectId);
    setValue('statusId', facture.statusId);
    setValue('numFacture', facture.numFacture);
    setValue('paiementType', facture.paiementType)
    facture.lignes.forEach((fl, i) => {
        remove(i);
        append({libelle: fl.libelle, QT: fl.QT, priceUnit: fl.priceUnit})
    });
    }, [facture, append, remove, setValue])

    

  return (
    <>
      {loading === true && <div style={{display:'flex'}}><div className='loader' style={{flex: 1}}><img alt='loader' src={loader}/></div></div>}
        {loading === false && 
            <Container className='mb-3'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='NumFact'>Numéro de facture </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">{facture.years}-</InputGroup.Text>
                                <input type="number" disabled={0 !== facture.statusId } id='NumFact' className="form-control" placeholder="Numéro de facture" {...register("numFacture", {required: validation.requis})} />
                            </InputGroup>
                        </Col>
                        <Col>
                            {errors.NumFacture && <span className='error-text'>{errors.NumFacture.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='status'>Status de la facture</label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Select id="statusId" {...register("statusId", {required:validation.requis})}>
                                <option disabled value="">Choisir un status</option>
                                {
                                    status.map((status, index) => {
                                        return (
                                            <option key={index} disabled={ !statusFiltered.includes(status) }  value={status.id}>{status.status}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col>
                            {errors.status && <span className='error-text'>{errors.status.message}</span>}
                        </Col>
                    </Row >
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfEdition'>date d'émission</label>
                    </Row>
                    <Row className='my-3'>
                    <Col>
                            <input type="date" defaultValue={facture.dateOfEdition} disabled={0 !== facture.statusId }  id="dateOfEdition" className="form-control" {...register("dateOfEdition")} />
                        </Col>
                        <Col>
                            {errors.dateOfEdition && <span className='error-text'>{errors.dateOfEdition.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfLimitPayement'>date limite de paiement</label>
                    </Row>
                    <Row className='my-3'>
                    <Col>
                            <input type="date" defaultValue={facture.dateLimitOfPayement} disabled={0 !== facture.statusId } id="dateLimitOfPayement" className="form-control" {...register("dateLimitOfPayement")} />
                        </Col>
                        <Col>
                            {errors.dateLimitOfPayement && <span className='error-text'>{errors.dateLimitOfPayement.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfPayement'>date de paiement</label>
                    </Row>
                    <Row className='my-3'>
                    <Col>
                            <input type="date" id="dateOfPayement" defaultValue={facture.dateOfPayement} className="form-control" {...register("dateOfPayement")} />
                        </Col>
                        <Col>
                            {errors.dateOfPayement && <span className='error-text'>{errors.dateOfPayement.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='Projets'>Liés aux projets </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Select disabled={0 !== facture.statusId } defaultValue="" id="Projets" {...register("projectId", {required:validation.requis})}>
                            <option disabled value="" >Choisir un Projets</option>
                                {
                                    projets.map((p, index) => {
                                        return (
                                            <option key={index} value={p.id}>{p.name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col>
                            {errors.Projets && <span className='error-text'>{errors.Projets.message}</span>}
                        </Col>
                    </Row >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='paiementType'>Type de paiement </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Select readOnly={0 !== facture.statusId} disabled={0 !== facture.statusId} id="paiementType" {...register("paiementType")}>
                            <option value="" disabled>Choisir un type de paiement</option>
                                {
                                    paiementType.map((pt, index) => {
                                        return (
                                            <option key={index} value={pt.id}>{pt.paiementType}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col>
                            {errors.paiementType && <span className='error-text'>{errors.paiementType.message}</span>}
                        </Col>
                    </Row >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='FacturationLine'>Ligne de facturation </label>
                    </Row>
                    <div id='FacturationLine'>
                        {
                            fields.map((field, index) => {
                                return (
                                    <Row key={field.id} className='my-3'>
                                        <Row className='my-3'>
                                            <Col>
                                                <input disabled={0 !== facture.statusId } type="text" id='FacturationLineT' className="form-control" placeholder="libelle" {...register(`facturationligne[${index}].libelle`)} />
                                            </Col>
                                            <Col>
                                                <input disabled={0 !== facture.statusId } type="number" id='FacturationLineQ' className="form-control" placeholder="prix" {...register(`facturationligne[${index}].priceUnit`)} />
                                            </Col>
                                            <Col>
                                                <input disabled={0 !== facture.statusId } type="number" id='FacturationLineS' className="form-control" placeholder="quantite" {...register(`facturationligne[${index}].QT`)} />
                                            </Col>
                                            <Col lg={2}>
                                                { ("0" === facture.statusId || 0 === facture.statusId) && <button type="button" className="form-control btn btn-secondary" onClick={() => {remove(index)}}>Supprimer</button>}
                                            </Col>
                                        </Row>
                                        {
                                            errors.facturationligne?.[index] &&
                                            <Row className='my-3'>
                                                <Col>
                                                    {errors.facturationligne?.[index].libelle && <span className='error-text'>{errors.facturationligne?.[index].libelle.message}</span>}
                                                </Col>
                                                <Col>
                                                    {errors.facturationligne?.[index].priceUnit && <span className='error-text'>{errors.facturationligne?.[index].priceUnit.message}</span>}
                                                </Col>
                                                <Col>
                                                    {errors.facturationligne?.[index].QT && <span className='error-text'>{errors.facturationligne?.[index].QT.message}</span>}
                                                </Col>
                                            </Row>
                                        }
                                    </Row >
                                )
                            })
                        }
                        {errorsPostSubmit.message && <span className='error-text'>{errorsPostSubmit.message}</span>}
                        { 0 === facture.statusId && <button type="button"  className="form-control btn btn-secondary" onClick={() => {append();setErrorsPostSubmit({})}}>Ajouter une ligne</button>}
                    </div>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='FootNote'>Note de bas de page </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <input readOnly={0 !== facture.statusId} disabled={0 !== facture.statusId} type="text" id='FootNote' className="form-control" placeholder="Note de bas de page" {...register("footNote")} />
                        </Col>
                        <Col>
                            {errors.footNote && <span className='error-text'>{errors.footNote.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={3}>
                            <input className='form-control btn btn-success' type="submit"  value="Editer la facture"/>
                        </Col>
                        <Col xs={3}>
                            <button type="button" className="form-control btn btn-warning" onClick={() => {backToList()}}>Annuler</button>
                        </Col>
                    </Row>
                </form>
            </Container>
    }
        </>
  )
}