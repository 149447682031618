import React from 'react'
import Clients from '../components/Clients.list'
import { BackendService } from '../Service/BackendService'

export default function ClientsController({clients, error, setError, goToAdd, goToEdit, setClients}) {
    const backendService = new BackendService();
    let clientToPass = clients;

    const deleteClientById = async (id) => {
        const response = await backendService.deleteClientById(id);
        return response;
    }

    const loadClients = async (setValues, setError) => {
        const response = await backendService.getAllClients();
        if (response.status === 200) {
            setValues(response.data);
            setError('none');
        }else if (response.status === 401) {
            localStorage.clear();
            window.location.reload();
          }else{
            setError(response.error);
        }
    }

    const searchClientByText = async (filter, text) => {
        if(text === ''){
            loadClients(setClients, setError)
            return;
        }
        const response = await backendService.searchClientByText(filter, text);
        if(response.status === 200){
            if(response.data.length === 0){
                error = 'No clients found';
            }else{
                error = 'none';
                clientToPass = response.data;
                setClients(clientToPass);
            }
        }else if (response.status === 401) {
            localStorage.clear();
            window.location.reload();
          }else{
            error = response.error;
        }
        return response;
    }

    return (
        <Clients clients={clientToPass} errors={error} goToAdd={goToAdd} deleteClientById={deleteClientById} goToEdit={goToEdit} searchClientByText={searchClientByText}/>
    )
}
