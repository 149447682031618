import React from 'react'
import NavbarBusinez from '../components/NavbarBusinez';

export default function NavbarBusinezController({setIsConnected, isConnected, pageObj}) {
    
  const logOut = () => {
    setIsConnected(false);
    sessionStorage.clear();
    window.location.href = '/';
  }
    
  return (
    <NavbarBusinez logOut={logOut} isConnected={isConnected} page={pageObj} />
  )
}
