import React from 'react'
import Admin from '../components/Admin';
import { BackendService } from '../Service/BackendService';

export default function AdminController() {
  const backendService = new BackendService();

  const loadUserData = async (setValues, setError) => {
    const response = await backendService.getAllUsers();
    if (response.status === 200) {
        setValues(response.data);
    }else{
        setError(response.error);
    }
}

  return (
    <Admin loadUserData={loadUserData}/>
  )
}
