import React from 'react'
import FacturesAdd from '../components/Factures.add';
import { BackendService } from '../Service/BackendService';

export default function FacturesControllerAdd({resetFactures, goToFactures, numOfFacture}) {
  const backendService = new BackendService();

  let maxNumOfFactures = numOfFacture ? numOfFacture.numFacture+1 : 0;

  const Submit = async (data) => {
    data.numFacture = new Date().getFullYear() + "-" + data.NumFact;
    const response = await backendService.addFacture(data);
    if (response.status === 201) {
      resetFactures();
    }else if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }else{
        alert(response.data);
    }
  }

  const loadProjets = async (setValues, setError) => {
    const response = await backendService.getAllProjects();
    if (response.status === 200) {
        setValues(response.data);
        setError('none');
    }else if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }else{
        setError(response.error);
    }
}

  const loadStatusFactures = async (setValues, setError) => {
    const response = await backendService.getAllFacturesStatus();
    if (response.status === 200) {
        setValues(response.data);
        setError('none');
    }else if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }else{
        setError(response.error);
    }
  }
  
  const loadPaiementType = async (setValues, setError) => {
    const response = await backendService.getAllPaiementType();
    if (response.status === 200) {
        setValues(response.data);
        setError('none');
    }else if (response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }else{
        setError(response.error);
    }
  }

  return (
    <div>
      <FacturesAdd backToList={goToFactures} maxNumOfFactures={maxNumOfFactures} Submit={Submit} loadProjets={loadProjets} loadStatus={loadStatusFactures} loadPaiementType={loadPaiementType} />
    </div>
  )
}