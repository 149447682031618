import React from 'react'
import { useState, useEffect } from 'react'
import ProjectsControllerList from './ProjectController.list'
import ProjectsControllerAdd from './ProjectController.add'
import ProjectsControllerEdit from './ProjectController.edit'
import { BackendService } from '../Service/BackendService';

export default function ProjectsController() {

    
    const [values, setValues] = useState([]);
    const [project, setProject] = useState({});
    const [error, setError] = useState(null);

    const [page, setPage] = useState('list');

    function goToAdd() {
        setPage('add');
    }

    function goToEdit(id) {
        values.filter((projects) => {
            if (projects.id === id) {
                setProject(projects);
                return projects;
            }
            return null;
        });
        setPage('edit');
    }

    function goToProjects() {
        setPage('list')
    }

    function resetProjects() {
        setPage('list')
        setError('loading')
    }

    

    useEffect(() => {
        if(page === 'list')
        {
            const backendService = new BackendService();
            const loadProjects = async (setValues, setError) => {
                const response = await backendService.getAllProjects();
                if (response.status === 200) {
                    setValues(response.data);
                    setError('none');
                }else if (response.status === 401) {
                    localStorage.clear();
                    window.location.reload();
                  }else{
                    setError(response.error);
                }
            }

            loadProjects(setValues, setError);
        }
    }, [page] )

    useEffect(() => {
        if(page === 'list' && project.id !== undefined){
            let newValues = []
            values.forEach((c) => {
                if (c.id === project.id) {
                    newValues.push(project); 
                    setProject({});
                }else {
                    newValues.push(c);
                }
            });
            setValues(newValues);
        }
    }, [page,project,values])

  return (
    <>
    {
        page === 'list' &&
        <ProjectsControllerList projects={values} setProject={setProject} setProjectss={setValues} error={error} setError={setError} goToAdd={goToAdd} goToEdit={goToEdit} />
    }
    {
        page === 'add' &&
        <ProjectsControllerAdd resetProjects={resetProjects} goToProjects={goToProjects}/>
    }
    {
        page === 'edit' &&
        <ProjectsControllerEdit project={project} goToProjectss={goToProjects} resetProjects={resetProjects} setProjects={setProject}/>
    }
    </>
  )
}
