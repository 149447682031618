import './App.css';
import { useEffect, useState } from 'react';
import LoginController from './Controller/LoginController';
import RegisterController from './Controller/RegisterController';
import NavbarBusinezController from './Controller/NavbarBusinezController';
import jwtDecode from 'jwt-decode';
import AdminController from './Controller/AdminController';
import ProfileController from './Controller/ProfileController';
import ClientsController from './Controller/ClientsController'
import ProjectsController from './Controller/ProjectsController';
import FacturesController from './Controller/FacturesController';
import HomeController from './Controller/HomeController';


//function App() {
function App() {
  const [isloading, setIsloading] = useState(true)
  const [page, setPage] = useState('Accueil')
  const [isConnect, setIsConnect] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false)
    }, 1000)
  }, [])

  useEffect(()=>
  {
    let token = sessionStorage.getItem('token');

    if(sessionStorage.getItem('token'))
    {
      let decoded = jwtDecode(token);
      let dateNow = parseInt(Date.now() / 1000);
      if(decoded.exp < dateNow)
      {
        sessionStorage.clear();
        window.location.href = '/';
      }else{
        setIsConnect(true);
      }
    }
  },[])
  
  return (
    isloading === true ? <div>Chargement...</div> :
    <>
      <NavbarBusinezController id="navbar" setIsConnected={setIsConnect} isConnected={isConnect} pageObj={{page:page, changementDePage:setPage }} />
      {
        page === 'login' &&
        <LoginController/>
      }
      {
        page === 'register' &&
        <RegisterController/>
      }
      {
        page === 'Accueil' &&
        <HomeController/>
      }
      {
        page === 'Clients' &&
        <ClientsController/>
      }
      {
        page === 'Projets' &&
        <ProjectsController/>
      }
      {
        page === 'Factures' &&
        <FacturesController/>
      }
      {
        page === 'Profile' &&
        <ProfileController/>
      }
      {
        page === 'admin' && 
        <AdminController/>
      }


    </>
  );
}

export default App;
