import React, { useEffect, useState } from 'react'
import { MonthFields } from '../Utils/MonthFields';
import { Chart as ChartJS, CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend, BarElement} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2'; 
import { Container, Row, Col } from 'react-bootstrap';
import loader from '../images/loader.svg'

export default function Home({getCAByMonthForYear, getCAByMonthForYearCumul, getSummaryForYear, getSummaryForTrim}) {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );

      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
      };
    //useState
    const [CAByMonthForYear, setCAByMonthForYear] = useState([]);
    const [errorCa, setErrorCa] = useState('');
    const [CAByMonthForYearCumul, setCAByMonthForYearCumul] = useState([]);
    const [errorCaCumul, setErrorCaCumul] = useState('');
    const [summaryCA, setSummaryCA] = useState({});
    const [summaryCAError, setSummaryCAError] = useState('');
    const [summaryTrimCA, setSummaryTrimCA] = useState({});
    const [summaryTrimCAError, setSummaryTrimCAError] = useState('');
    const [loading, setLoading] = useState(true);
    const [year, setYear] = useState(new Date().getFullYear());
    //useEffect
    useEffect(() => {
        Promise.all([
            getCAByMonthForYear(setCAByMonthForYear, setErrorCa, year),
            getCAByMonthForYearCumul(setCAByMonthForYearCumul, setErrorCaCumul, year),
            getSummaryForYear(setSummaryCA, setSummaryCAError, year),
            getSummaryForTrim(setSummaryTrimCA, setSummaryTrimCAError,1)
        ]).then(() => {
            setLoading(false);
        })
    }, [setCAByMonthForYear, setErrorCa, getCAByMonthForYear, year, setCAByMonthForYearCumul, setErrorCaCumul, getCAByMonthForYearCumul, getSummaryForYear, setSummaryCA, setSummaryCAError, getSummaryForTrim, setSummaryTrimCA, setSummaryTrimCAError])

    useEffect(() => {
        let e = errorCa === 'none' && errorCaCumul === 'none' && summaryCA === 'none' && summaryTrimCAError === 'none' ? false : true;
        let t = (e) => null
        t(e);
        setYear(new Date().getFullYear());
    }, [errorCa,errorCaCumul,summaryCA,summaryCAError, summaryTrimCAError])

    const dataCa = {
        labels: MonthFields,
        datasets: [
            {
                label: 'Chiffre d\'affaire',
                data: CAByMonthForYear,
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

    const dataCaCumul = {
        labels: MonthFields,
        datasets: [
            {
                label: 'Chiffre d\'affaire cumulé',
                data: CAByMonthForYearCumul,
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };

  return (
    CAByMonthForYear.length !== 0 && loading === false ?
        <Container fluid>
            <Row style={{marginBottom: 50 +'px'}}>
                <Col sm={6}>
                    <h1>Résumé de l'activité annuel</h1>
                    <table className="table table-striped table-borderless">
                        <thead>
                            <th>CA Annuel</th>
                            <th>Somme paiements en attente</th>
                            <th>Somme factures éditées</th>
                            <th>CA Annuel Max</th>
                            <th>CA Annuel restant</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{summaryCA.annualCA}</td>
                                <td>{summaryCA.valueWaitingForPayement}</td>
                                <td>{summaryCA.valueInEditingState}</td>
                                <td>{summaryCA.MaxAnnualCA}</td>
                                <td>{summaryCA.annualCARest}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col sm={6}>
                    <h1>Résumé de l'activité du trimestre</h1>
                    <table className="table table-striped table-borderless">
                        <thead>
                        <th>CA Payé</th>
                        <th>CA Estimé</th>
                        <th>Charges à Payer</th>
                        <th>Charges Estimées à Payer</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{summaryTrimCA.CApayed}</td>
                                <td>{summaryTrimCA.estimatedCA}</td>
                                <td>{summaryTrimCA.Charges}</td>
                                <td>{summaryTrimCA.estimatedCharges}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <Row>
                <Col sm={6} >
                    <h1>Chiffre d'affaire pour l'année {year}</h1>
                    <Bar data={dataCa} options={options} />
                </Col>
                <Col sm={6}>
                    <h1>Chiffre d'affaire cumulé pour l'année {year}</h1>
                    <Line data={dataCaCumul} options={options} />
                </Col>
            </Row>
        </Container>
    :  
    CAByMonthForYear.length === 0 && loading === false ?
        <Container fluid>
            <h1>Bienvenue sur Businez</h1>
            <h2>Veuillez vous connecter ou créer un compte afin d'avoir accès à toutes les fonctionnalités</h2>
        </Container>
        :
    <div className='loader'><img alt='loader' src={loader}/></div>
  )
}
