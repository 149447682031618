import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, InputGroup } from 'react-bootstrap'
import { useForm, useFieldArray } from 'react-hook-form'
import loader from '../images/loader.svg'

import { validation } from '../Utils/validation';

export default function FacturesAdd({ backToList, Submit, loadProjets, loadStatus, loadPaiementType, maxNumOfFactures}) {
  const [projets, setProjets] = useState([]);
  const [errorProjets, setErrorProjets] = useState(null);
  const [status, setStatus] = useState([]);
  const [errorStatus, setErrorStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paiementType, setPaiementType] = useState([]);
  const [errorPaiementType, setErrorPaiementType] = useState(null);
  const year = new Date().getFullYear()
  const [errorsPostSubmit, setErrorsPostSubmit] = useState({})
  const [statusFiltered, setStatusFiltered] = useState([])

  const { register, handleSubmit, unregister, getValues, watch, formState: { errors }, control } = useForm({
    defaultValues: {
        facturationligne: [{}]
    },
        mode:'onTouched'
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "facturationligne"
    });
    const t = watch("status")

    const onSubmit = data => {
        if(data.status === '0' || data.status === 0 || data.facturationligne.length !== 0) {
            setErrorsPostSubmit({})
            Submit(data)
        }else {
            setErrorsPostSubmit({ message: 'Vous devez ajouter au moins une ligne de facturation'})
        }
    }

    useEffect(() => {
        setStatusFiltered(status.slice(0,2));
    }, [status])

  useEffect(() => {
    Promise.all([
        loadStatus(setStatus, setErrorStatus),
        loadProjets(setProjets, setErrorProjets),
        loadPaiementType(setPaiementType, setErrorPaiementType)
    ]).then(() => {
        setLoading(false);
        let e = errorProjets === 'none' && errorStatus === 'none' && errorPaiementType === 'none' ? false : true;
        let t = (e) => null
        t(e);
    });
  },[loadProjets,loadStatus, loadPaiementType, errorPaiementType, errorProjets, errorStatus] )

  useEffect(() => {
    if(t === "0"){
        unregister(["dateOfEdition","dateLimitOfPayement","dateOfPayement","paiementType","footNote"]);
    } else {
        unregister(["dateOfEdition","dateLimitOfPayement","dateOfPayement","paiementType","footNote"]);
        register("dateOfEdition", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom});
        register("dateLimitOfPayement", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom, validate: value => new Date(value) > new Date(getValues('dateOfEdition')) || validation.dateLimite});
        register("dateOfPayement");
        register("paiementType", {required: validation.requis});
        register("footNote", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom});
    }
  }, [t, register, unregister,getValues])


  return (
    <>
      {loading === true && <div style={{display:'flex'}}><div className='loader' style={{flex: 1}}><img alt='loader' src={loader}/></div></div>}
        {loading === false && 
            <Container className='mb-3'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='NumFact'>Numéro de facture </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">{year}-</InputGroup.Text>
                                <input type="number" id='NumFact' className="form-control" placeholder="Numéro de facture" {...register("NumFact", {required: validation.requis})} />
                            </InputGroup>
                        </Col>
                        <Col>
                            {errors.NumFact && <span className='error-text'>{errors.NumFact.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='status'>Status de la facture</label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Select defaultValue="" id="status" {...register("status", {required:validation.requis})}>
                                <option disabled value="">Choisir un status</option>
                                {
                                    status.map((status, index) => {
                                        return (
                                            <option key={index} disabled={ !statusFiltered.includes(status) } value={status.id}>{status.status}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col>
                            {errors.status && <span className='error-text'>{errors.status.message}</span>}
                        </Col>
                    </Row >
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfEdition'>date d'émission</label>
                    </Row>
                    <Row className='my-3'>
                    <Col>
                            <input type="date" id="dateOfEdition" className="form-control" {...register("dateOfEdition")} />
                        </Col>
                        <Col>
                            {errors.dateOfEdition && <span className='error-text'>{errors.dateOfEdition.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfLimitPayement'>date limite de paiement</label>
                    </Row>
                    <Row className='my-3'>
                    <Col>
                            <input type="date" id="dateLimitOfPayement" className="form-control" {...register("dateLimitOfPayement")} />
                        </Col>
                        <Col>
                            {errors.dateLimitOfPayement && <span className='error-text'>{errors.dateLimitOfPayement.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <label className='form-label' htmlFor='dateOfPayement'>date de paiement</label>
                    </Row>
                    <Row className='my-3'>
                    <Col>
                            <input type="date" id="dateOfPayement" className="form-control" {...register("dateOfPayement")} />
                        </Col>
                        <Col>
                            {errors.dateOfPayement && <span className='error-text'>{errors.dateOfPayement.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='Projets'>Liés aux projets </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Select defaultValue="" id="projectId" {...register("projectId", {required:validation.requis})}>
                            <option disabled value="">Choisir un Projets</option>
                                {
                                    projets.map((p, index) => {
                                        return (
                                            <option key={index} value={p.id}>{p.name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col>
                            {errors.projectId && <span className='error-text'>{errors.projectId.message}</span>}
                        </Col>
                    </Row >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='paiementType'>Type de paiement </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <Form.Select id="paiementType" defaultValue="" {...register("paiementType")}>
                            <option disabled value="">Choisir un type de paiement</option>
                                {
                                    paiementType.map((pt, index) => {
                                        return (
                                            <option key={index} value={pt.id}>{pt.paiementType}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col>
                            {errors.paiementType && <span className='error-text'>{errors.paiementType.message}</span>}
                        </Col>
                    </Row >
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='FacturationLine'>Ligne de facturation </label>
                    </Row>
                    <div id='FacturationLine'>
                        {
                            fields.map((field, index) => {
                                return (
                                    <Row key={field.id} className='my-3'>
                                        <Row className='my-3'>
                                            <Col>
                                                <input type="text" id='FacturationLineT' className="form-control" placeholder="libelle" {...register(`facturationligne[${index}].libelle`, {required: validation.requis, minLength: validation.libelle, maxLength: validation.maxlibelle})} />
                                            </Col>
                                            <Col>
                                                <input type="number" id='FacturationLineQ' className="form-control" placeholder="prix" {...register(`facturationligne[${index}].priceUnit`, {required: validation.requis, min: validation.prix})} />
                                            </Col>
                                            <Col>
                                                <input type="number" id='FacturationLineS' className="form-control" placeholder="quantite" {...register(`facturationligne[${index}].QT`, {required: validation.requis, min: validation.minQuantite})} />
                                            </Col>
                                            <Col lg={1}>
                                                <button type="button" className="form-control btn btn-secondary" onClick={() => {remove(index)}}>Supprimer</button>
                                            </Col>
                                        </Row>
                                        {
                                            errors.facturationligne?.[index] &&
                                            <Row className='my-3'>
                                                <Col>
                                                    {errors.facturationligne?.[index].libelle && <span className='error-text'>{errors.facturationligne?.[index].libelle.message}</span>}
                                                </Col>
                                                <Col>
                                                    {errors.facturationligne?.[index].priceUnit && <span className='error-text'>{errors.facturationligne?.[index].priceUnit.message}</span>}
                                                </Col>
                                                <Col>
                                                    {errors.facturationligne?.[index].QT && <span className='error-text'>{errors.facturationligne?.[index].QT.message}</span>}
                                                </Col>
                                            </Row>
                                        }
                                    </Row >
                                )
                            })
                        }
                        {errorsPostSubmit.message && <span className='error-text'>{errorsPostSubmit.message}</span>}
                        <button type="button" className="form-control btn btn-secondary" onClick={() => {append()}}>Ajouter une ligne</button>
                    </div>
                    <Row className='my-3'>
                    <label className='form-label' htmlFor='footNote'>Note de bas de page </label>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <input type="text" id='footNote' className="form-control" placeholder="Note de bas de page" {...register("footNote")} />
                        </Col>
                        <Col>
                            {errors.footNote && <span className='error-text'>{errors.footNote.message}</span>}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col xs={3}>
                            <input className='form-control btn btn-success' type="submit"  value="Créer la facture"/>
                        </Col>
                        <Col xs={3}>
                            <button type="button" className="form-control btn btn-warning" onClick={() => {backToList()}}>Annuler</button>
                        </Col>
                    </Row>
                </form>
            </Container>
    }
        </>
  )
}
