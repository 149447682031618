import { baseUrl } from "./BackendService";
class ClientsApi {
    constructor() {
        this.baseUrl = baseUrl +'/api/client';
    }

    async getAllClients() {
        let RES = {};
        await fetch(this.baseUrl, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la récupération des clients du client")
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async addClient(data) {
        let RES = {};
        let d = JSON.stringify(data)
        await fetch(this.baseUrl, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: d
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 201){
                return r.json()
            }else if (r.status === 200){
                throw new Error("Client déjà existant")
            }else{
                throw new Error("Erreur lors de la création du client")
            }
        })
        .then(response => { RES.message = "Clients créer avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async deleteClientById(id) {
        let RES = {};
        await fetch(this.baseUrl + '/' + id, {
            method: 'Delete',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            }
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la suppression du client")
            }
        })
        .then(response => { RES.message = "Client supprimé avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }
    
    async updateClientById (id, data) {
        let RES = {};
        let d = JSON.stringify(data)
        await fetch(this.baseUrl + '/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            body: d
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la modification du client")
            }
        })
        .then(response => { RES.message = "Client modifié avec succès" })
        .catch(error => { RES.data = error.message })
        return RES;
    }

    async searchClientByText(filter, text) {
        let RES = {};
        await fetch(this.baseUrl + '/search/' + filter + '/' + text, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        }).then(r =>
            {
            RES.status =  r.status
            if(r.status === 200){
                return r.json()
            }else{
                throw new Error("Erreur lors de la recherche du client")
            }
        })
        .then(response => { RES.data = response })
        .catch(error => { RES.data = error.message })
        return RES;
    }
    
}

export { ClientsApi };