import React from 'react'
import { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { validation } from '../Utils/validation';
import loader from '../images/loader.svg'

export default function ClientEdit({ backToList, Submit, client}) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({mode:'onTouched'})

    const [loading, setLoading] = useState(true);

    const onSubmit = data => {
      Submit(client.id,data)
    }
  
    useEffect(() => {
        if(client.id !== 0 ){
            setLoading(false);
            setValue("name", client.name);
            setValue("nameOfContactOrFirstName", client.nameOfContactOrFirstName);
            setValue("isEntreprise", client.isEntreprise === true ? 1 : 0);
            setValue("mail", client.mail);
            setValue("PhoneNumber", client.PhoneNumber);
            setValue("Address", client.Address);
            setValue("AdresseCmplt", client.AdresseCmplt);
            setValue("ZipCode", client.ZipCode);
            setValue("City", client.City);
        }
    }, [client, setValue]);

    return (
      <>
          {loading === true && <div className='loader'><img alt='loader' src={loader}/></div>}
              <Container className='mb-3'>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <Row className='my-3'>
                  <Col>
                          <input type="text" className="form-control" placeholder="Nom du clients" {...register("name", {required: validation.requis, minLength: validation.nom, maxLength: validation.maxNom})} />
                      </Col>
                      <Col>
                          {errors.name && <span className='error-text'>{errors.name.message}</span>}
                      </Col>
                  </Row>
                  <Row className='my-3'>
                      <Col>
                          <input type="text" className="form-control" placeholder="Prénom/Nom de contact" {...register("nameOfContactOrFirstName", {required: validation.requis, minLength: validation.prenom, maxLength: validation.maxPrenom})} />
                      </Col>
                      <Col>
                          {errors.nameOfContactOrFirstName && <span className='error-text'>{errors.nameOfContactOrFirstName.message}</span>}
                      </Col>
                  </Row >
                  <Row className='my-3'>
                      <Col xs={6}>
                          <Form.Select {...register("isEntreprise")}>
                              <option value="0">Personne</option>
                              <option value="1">Entreprise</option>
                          </Form.Select>
                      </Col>
                  </Row >
                  <Row className='my-3'>
                      <Col>
                          <input type="text" className="form-control" placeholder="mail" {...register("mail", {required: validation.requis, pattern: validation.email})} />
                      </Col>
                      <Col>
                          {errors.mail && <span className='error-text'>{errors.mail.message}</span>}
                      </Col>
                  </Row>
                  <Row className='my-3'>
                      <Col>
                          <input type="tel" className="form-control" placeholder="Numéro de téléphone" {...register("PhoneNumber", {required: validation.requis,minLength: validation.telephone, maxLength: validation.telephone})} />
                      </Col>
                      <Col>
                          {errors.PhoneNumber && <span className='error-text'>{errors.PhoneNumber.message}</span>}
                      </Col>
                  </Row>
                  <Row className='my-3'>
                      <Col>
                          <input type="text" className="form-control" placeholder="Adresse" {...register("Address", {required: validation.requis, minLength:validation.adresse, maxLength: validation.maxAdresse})} />
                      </Col>
                      <Col>
                          {errors.Adresse && <span className='error-text'>{errors.Adresse.message}</span>}
                      </Col>
                  </Row>
                  <Row className='my-3'>
                      <Col>
                          <input type="text" className="form-control" placeholder="Complément d'adresse" {...register("AdresseCmplt", {maxLength: validation.maxAdresse})} />
                      </Col>
                      <Col>
                          {errors.AdresseCmplt && <span className='error-text'>{errors.AdresseCmplt.message}</span>}
                      </Col>
                  </Row>
                  <Row className='my-3'>
                      <Col>
                          <input type="text" className="form-control" placeholder="Code postal" {...register("ZipCode", {required: validation.requis, maxLength: validation.codePostal, minLength: validation.codePostal})} />
                      </Col>
                      <Col>
                          {errors.CodePostal && <span className='error-text'>{errors.CodePostal.message}</span>}
                      </Col>
                  </Row>
                  <Row className='my-3'>
                      <Col>
                          <input type="text" className="form-control" placeholder="Ville" {...register("City", {required: validation.requis, maxLength: validation.maxVille})} />
                      </Col>
                      <Col>
                          {errors.Ville && <span className='error-text'>{errors.Ville.message}</span>}
                      </Col>
                  </Row>
                  <Row className='my-3'>
                      <Col xs={3}>
                          <input disabled={0 < Object.values(errors).length ? true : false} className='form-control btn btn-success' type="submit"  value="Modifier le client"/>
                      </Col>
                      <Col xs={3}>
                          <button type="button" className="form-control btn btn-warning" onClick={() => {backToList()}}>Annuler</button>
                      </Col>
                  </Row>
              </form>
              </Container>
          </>
    )
}
